import { config } from 'mlp-client/src/app-config'
import { DistanceUnit } from 'mlp-client/src/enums'
import { contractsConfig } from 'mlp-client/src/features-configuration/de/contracts'
import { dashboardConfig } from 'mlp-client/src/features-configuration/de/dashboard'
import { endOfLeaseFeature } from 'mlp-client/src/features-configuration/de/endOfLease'
import { fuelCardsConfig } from 'mlp-client/src/features-configuration/de/fuelCards'
import { glassRepairFeature } from 'mlp-client/src/features-configuration/de/glassRepair'
import { tyresConfig } from 'mlp-client/src/features-configuration/de/tyres'
import {
  vehicleDocumentsFeature,
  vehicleDocumentsPageFeature,
} from 'mlp-client/src/features-configuration/de/vehicleDocuments'
import {
  ApiType,
  MyleaseplanConfig,
  SharedMyLeaseplanConfig,
} from 'mlp-client/src/features-configuration/types'
import { UserProfileAddressKeys as Address } from 'mlp-client/src/user/enums'
import { navigation } from 'mlp-client/src/features-configuration/de/navigation'
import { loginConfig } from 'mlp-client/src/features-configuration/de/login'
import {
  GlassIcon,
  MaintenanceIcon,
} from 'mlp-client/src/dashboard/components/icons'
import ContractIcon from 'mlp-client/src/components/icons/contract-icon/ContractIcon'
import { isLeaseplan } from 'mlp-client/src/VelocitySelector'

export const sharedMyLeaseplanConfig: SharedMyLeaseplanConfig = {
  enabled: !isLeaseplan(),
  loginEnabled:
    process.env.ENABLE_MY_LEASEPLAN === 'true' ||
    config.ENABLE_MY_LEASEPLAN ||
    false,
  accountLinkEnabled: false,
  myAccountLink: 'myLeaseplan.homepage',
}

export const baseMyLeaseplanConfig: MyleaseplanConfig = {
  ...sharedMyLeaseplanConfig,
  isExternalLoginEnabled: !isLeaseplan(),
  apiType: ApiType.lpis,
  distanceUnit: DistanceUnit.KM,
  alternatePhoneDisplay: false,
  addressFormat: [
    `{${Address.street}} {${Address.number}}`,
    `{${Address.zipCode}}, {${Address.city}}`,
    `{${Address.country}}`,
  ],
  vehicleDocuments: {
    enabled: true,
    mobileApp: {
      showNativeHeader: true,
    },
    lpis: {
      estimatedTimeOfDelivery: 3,
    },
  },
  isInstallMobileAppEnabled: true,
  contracts: contractsConfig,
  supportsMultipleVehicles: {
    enabled: !isLeaseplan(),
    configuration: {
      tileLinks: () => ({
        tileLinksFirstColumn: [
          {
            to: 'https://bookingtool.leaseplan.de/web-app-bookingtool',
            dataE2eId: 'bookService',
            icon: MaintenanceIcon,
            translationId: 'maintenance',
            target: '_blank',
          },
          {
            to: 'https://leaseplan.autohilfe.eu/#/LLLLLPPPPPDDDDD',
            dataE2eId: 'DamageIcon',
            icon: GlassIcon,
            translationId: 'breakdown',
            target: '_blank',
          },
        ],
        tileLinksSecondColumn: [
          {
            to: 'myLeaseplan.contract.detail',
            dataE2eId: 'viewContract',
            icon: ContractIcon,
            translationId: 'contract',
          },
        ],
      }),
    },
  },
}

export const myleaseplanConfig: MyleaseplanConfig = {
  ...baseMyLeaseplanConfig,
  navigation,
  login: loginConfig,
  showPrivateAddressLabel: true,
  showCompanyAddress: true,
  showSalutation: true,
  tyres: tyresConfig,
  endOfLease: endOfLeaseFeature,
  fuelCard: fuelCardsConfig,
  glassRepair: glassRepairFeature,
  dashboard: dashboardConfig,
  contracts: contractsConfig,
  vehicleDocuments: vehicleDocumentsFeature,
  vehicleDocumentsPage: vehicleDocumentsPageFeature,
  mileageUpdate: {
    enabled: false,
  },
  profile: {
    hideCountryCode: true,
  },
}
