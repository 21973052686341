import { User } from 'mlp-client/src/user/types'
import {
  getFranceUserHomeAddress,
  getUserHomeAddress,
} from 'mlp-client/src/user/utils'
import { SubmitProfileFormTypes } from 'mlp-client/src/profile/types'
import { getAddressModel } from 'mlp-client/src/utils'
import { Locales } from 'mlp-client/src/localization/enums'
import { isFrance } from 'mlp-client/src/order-status/selectors'

export const getInitialValues = (
  user: User,
  locale?: Locales,
): SubmitProfileFormTypes => {
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    phoneCountryCode,
    mobilePhoneCountryCode,
    mobilePhoneNumber,
    correspondenceLanguageCode,
  } = user
  const {
    addressLine1,
    houseNumber,
    houseNumberSuffix,
    city,
    country,
    zipCode,
  } = isFrance(locale)
    ? getFranceUserHomeAddress(user.addresses) || getAddressModel({})
    : getUserHomeAddress(user.addresses) || getAddressModel({})

  return {
    firstName,
    lastName,
    email,
    phone: {
      number: phoneNumber,
      code: phoneCountryCode,
    },
    mobile: {
      number: mobilePhoneNumber,
      code: mobilePhoneCountryCode,
    },
    correspondenceLanguageCode,
    address: {
      addressLine1,
      houseNumber,
      houseNumberSuffix,
      zipCode,
      city,
      country,
    },
  }
}
