import { Options } from 'mlp-client/src/form/types'
import { getAddressModel } from 'mlp-client/src/utils'
import { getFormattedAddress } from 'mlp-client/src/utils/address/address'

import {
  AddressData,
  Address,
  AddressTypes,
  User,
  ContactInfo,
  UserProfile,
  UserProfileAddress,
} from './types'

export const getUserModel = (user: User) => ({
  id: user.id,
  contractId: user.contractId ?? '',
  salutation: user.salutation ?? '',
  firstName: user.firstName,
  lastName: user.lastName,
  email: user.email,
  birthDate: user.birthDate,
  mobilePhoneNumber: user.mobilePhoneNumber ?? '',
  mobilePhoneCountryCode: user.mobilePhoneCountryCode ?? '',
  phoneNumber: user.phoneNumber ?? '',
  phoneCountryCode: user.phoneCountryCode ?? '',
  correspondenceLanguageCode: user.correspondenceLanguageCode ?? '',
  addresses: user.addresses?.map(getAddressModel) ?? [getAddressModel({})],
  brand: user.brand,
})

export const getUserHomeAddress = (
  addresses: User['addresses'],
): Address | undefined =>
  addresses.find(address => address.type === AddressTypes.HOME)

export const getFranceUserHomeAddress = (
  addresses: User['addresses'],
): Address | undefined => {
  const address = getUserHomeAddress(addresses)

  if (!address) {
    return undefined
  }

  const hasHouseNumber = Boolean(
    address.houseNumber || address.houseNumberSuffix,
  )

  address.addressLine1 = hasHouseNumber
    ? `${address.houseNumber}${address.houseNumberSuffix} ${address.addressLine1}`
    : address.addressLine1

  return address
}

export const getUserWorkAddress = (
  addresses: User['addresses'],
): Address | undefined =>
  addresses.find(address => address.type === AddressTypes.WORK)

export const getUserAddressByType = (
  user: User,
  addressType: AddressTypes,
): Address | undefined => {
  if (addressType === AddressTypes.WORK) {
    return getUserWorkAddress(user.addresses)
  }

  if (addressType === AddressTypes.HOME) {
    return getUserHomeAddress(user.addresses)
  }
}

export const getUserOtherAddress = (otherAddress?: AddressData): Address =>
  getAddressModel({
    type: AddressTypes.OTHER,
    addressLine1: otherAddress.street,
    houseNumber: otherAddress.houseNumber,
    zipCode: otherAddress.zipCode,
    city: otherAddress.city,
    country: otherAddress.country,
  })

export const getLocationOptions = (
  user: User,
  addressToHide?: AddressTypes[],
): Options => {
  const addresses = user.addresses
    .filter(
      addressToHide
        ? address => !addressToHide.includes(address.type)
        : Boolean,
    )
    .map(address => ({
      title: `myLeaseplan.newSetup.shared.forms.location.${address.type}`,
      subtitle: getFormattedAddress(address),
      value: address.type,
    }))

  if (!(addressToHide && addressToHide.includes(AddressTypes.OTHER))) {
    addresses.push({
      title: 'myLeaseplan.newSetup.shared.forms.location.other',
      value: AddressTypes.OTHER,
      subtitle: 'myLeaseplan.newSetup.shared.forms.location.otherDescription',
    })
  }

  return addresses
}

export const mapUserAddresses = (
  addresses: User['addresses'],
): UserProfileAddress[] =>
  addresses.map(address => ({
    street: address.addressLine1,
    number: address.houseNumber,
    suffix: address.houseNumberSuffix,
    zipCode: address.zipCode,
    city: address.city,
    stateProvince: address.province,
    country: address.country,
    type: address.type,
  }))

export const convertUserToUserProfile = (user: User): UserProfile => {
  const addresses = user.addresses && mapUserAddresses(user.addresses)

  return {
    phone: user.phoneNumber,
    phoneCountryCode: user.phoneCountryCode,
    mobile: user.mobilePhoneNumber,
    mobilePhoneCountryCode: user.mobilePhoneCountryCode,
    salutation: user.salutation,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    addresses: addresses ?? [],
  }
}

export const getUserContactsInformation = ({
  email,
  mobilePhoneNumber,
  mobilePhoneCountryCode,
  phoneCountryCode,
  phoneNumber,
}: User): ContactInfo => ({
  email,
  phone: {
    number: mobilePhoneNumber || phoneNumber,
    code: mobilePhoneNumber ? mobilePhoneCountryCode : phoneCountryCode,
  },
})

export const getUserFullName = (user: User): string =>
  `${user.firstName} ${user.lastName}`

const capitalizeName = (name: string): string =>
  name
    .split(' ')
    .map(name =>
      name
        .split('-')
        .map(name => `${name[0].toUpperCase()}${name.slice(1).toLowerCase()}`)
        .join('-'),
    )
    .join(' ')

export const getLoadUser = (user: User): User => ({
  ...user,
  firstName: capitalizeName(user.firstName),
  lastName: capitalizeName(user.lastName),
})
