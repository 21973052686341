import {
  MobileAppConfig,
  MyLeaseplanFeature,
} from 'mlp-client/src/features-configuration/types'
import {
  LocationStep,
  ThankYouStep,
  VehicleDocumentsFlow,
  VehicleDocumentsPage,
} from 'mlp-client/src/flows/vehicle-documents/components'
import { StepNames } from 'mlp-client/src/flows/vehicle-documents/enums'
import {
  Steps,
  VehicleDocumentsFlowConfiguration,
} from 'mlp-client/src/flows/vehicle-documents/types'
import { isLeaseplan } from 'mlp-client/src/VelocitySelector'

const allSteps: Steps = {
  [StepNames.LOCATION]: {
    stepView: LocationStep,
    stepConfig: {
      showOtherLocation: true,
      hideHouseNumber: true,
      hideCountry: true,
      isValidateByGeo: false,
    },
  },
  [StepNames.THANK_YOU]: {
    stepView: ThankYouStep,
    stepConfig: {
      estimatedTimeOfDelivery: 3,
    },
  },
}

export const vehicleDocumentsFeature: MyLeaseplanFeature<VehicleDocumentsFlowConfiguration> = {
  enabled: !isLeaseplan(),
  view: VehicleDocumentsFlow,
  configuration: {
    getSteps: () => [StepNames.LOCATION, StepNames.THANK_YOU],
    allSteps,
  },
}

export const vehicleDocumentsPageFeature: MobileAppConfig<{}> = {
  enabled: true,
  view: VehicleDocumentsPage,
  mobileApp: {
    showNativeHeader: true,
  },
}
