import { Grid, GridItem, Spacing } from '@leaseplan/ui'
import { Headline } from '@velocity/ui'
import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Form as ReactFinalForm, FormRenderProps } from 'react-final-form'
import { createStructuredSelector } from 'reselect'
import isEqual from 'lodash/isEqual'

import Translation from 'mlp-client/src/localization/Translation'
import {
  EditProfileConfig,
  ProfileField,
  SubmitProfileFormTypes,
} from 'mlp-client/src/profile/types'
import { User } from 'mlp-client/src/user/types'
import { TextField } from 'mlp-client/src/form/components/fields/TextFields'
import { SelectField } from 'mlp-client/src/form/components/fields/SelectFields'
import { FormSubmission, Options } from 'mlp-client/src/form/types'
import { required } from 'mlp-client/src/form/validations'
import SubmitStatus from 'mlp-client/src/form/components/fields/submit-status/SubmitStatus'
import SubmitButton from 'mlp-client/src/form/components/fields/submit-button/SubmitButton'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import PhoneInput from 'mlp-client/src/form/components/fields/PhoneInput/PhoneInput'
import { getCountries, getLanguages } from 'mlp-client/src/profile/selectors'
import { LoadCountryProfile } from 'mlp-client/src/profile/actions'
import { StyledButtonOutlined } from 'mlp-client/src/components/button/StyledButton'
import { Locales } from 'mlp-client/src/localization/enums'
import { getLocale } from 'mlp-client/src/localization/selectors'
import { AppState } from 'mlp-client/src/types'

import { getInitialValues } from './utils'

export interface Props extends FormSubmission<SubmitProfileFormTypes> {
  user: User
  config: EditProfileConfig
  languages: Options
  countries: Options
  loadCountryProfile(): void
  locale: Locales
}

export const StyledGrid = styled(Grid)`
  > ${GridItem} {
    flex: 1 0 100%;
    padding: 0 12px;
  }
`

export class EditProfileForm extends React.PureComponent<Props> {
  componentDidMount(): void {
    const { countries, loadCountryProfile } = this.props

    if (!countries.length) {
      loadCountryProfile()
    }
  }

  renderField(config: ProfileField) {
    const { wrapperProps, ...fieldProps } = config

    return (
      <GridItem {...wrapperProps} key={fieldProps.name}>
        <TextField {...fieldProps} />
        <Spacing mb={2} />
      </GridItem>
    )
  }

  render() {
    const {
      user,
      config: {
        isLanguageEnabled,
        isCountryEnabled,
        addressFields = [],
        userFields = [],
      },
      countries,
      languages,
      onSubmit,
      locale,
    } = this.props

    return (
      <ReactFinalForm<SubmitProfileFormTypes>
        onSubmit={onSubmit}
        initialValues={getInitialValues(user, locale)}
        initialValuesEqual={isEqual}
      >
        {({
          handleSubmit,
          submitting,
          submitFailed,
          pristine,
        }: FormRenderProps) => (
          <form onSubmit={handleSubmit}>
            <StyledGrid alignItems="baseline" justifyContent="space-between">
              <GridItem>
                <Spacing mb={2}>
                  <Headline variant="400" component="h1" withMarginBottom>
                    <Translation id="myLeaseplan.profile.edit.title" />
                  </Headline>
                </Spacing>
              </GridItem>
              {userFields.map(this.renderField)}
              <GridItem>
                <PhoneInput
                  name="phone"
                  label="myLeaseplan.shared.input.phone.label"
                  placeholder={{
                    id: 'myLeaseplan.shared.input.phone.placeholder',
                  }}
                />
                <Spacing mb={2} />
              </GridItem>
              <GridItem>
                <PhoneInput
                  name="mobile"
                  label="myLeaseplan.profile.user.mobilePhoneNumber"
                  placeholder={{
                    id: 'myLeaseplan.shared.input.phone.placeholder',
                  }}
                />
                <Spacing mb={2} />
              </GridItem>
              <GridItem>
                <Spacing mb={2}>
                  <Headline variant="200" component="h6">
                    <Translation id="myLeaseplan.profile.edit.homeAddress" />
                  </Headline>
                </Spacing>
              </GridItem>
              {addressFields.map(this.renderField)}
              {isCountryEnabled && (
                <GridItem>
                  <SelectField
                    name="address.country"
                    validations={[required]}
                    options={countries}
                    label={{
                      id: 'myLeaseplan.shared.input.address.country.label',
                    }}
                    dataE2eId="country"
                  />
                  <Spacing mb={2} />
                </GridItem>
              )}
              {isLanguageEnabled && (
                <GridItem>
                  <SelectField
                    name="correspondenceLanguageCode"
                    validations={[required]}
                    options={languages}
                    label={{
                      id: 'myLeaseplan.shared.input.language.label',
                    }}
                    dataE2eId="language"
                  />
                  <Spacing mb={2} />
                </GridItem>
              )}
            </StyledGrid>
            <SubmitStatus
              submitting={submitting}
              failed={submitFailed}
              submittingTranslation="myLeaseplan.profile.edit.submitting"
              failedTranslation="myLeaseplan.profile.edit.failed"
            >
              <Grid justifyContent="space-between">
                <GridItem>
                  <Spacing ph={1}>
                    <SubmitButton
                      label="myLeaseplan.profile.edit.update"
                      dataE2eId="button-edit-profile"
                      stretch={false}
                      disabled={pristine || submitting}
                    />
                  </Spacing>
                </GridItem>
                <GridItem>
                  <Spacing ph={1}>
                    <StyledButtonOutlined
                      component={RouteLink}
                      to="myLeaseplan.profile.detail"
                      variant="secondary"
                    >
                      <Translation id="myLeaseplan.profile.edit.cancel" />
                    </StyledButtonOutlined>
                  </Spacing>
                </GridItem>
              </Grid>
            </SubmitStatus>
          </form>
        )}
      </ReactFinalForm>
    )
  }
}
const selectedLocale = (state: AppState) => getLocale(state)

export const mapStateToProps = createStructuredSelector({
  countries: getCountries,
  languages: getLanguages,
  locale: selectedLocale,
})

export const mapDispatchToProps = {
  loadCountryProfile: () => new LoadCountryProfile(),
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileForm)
