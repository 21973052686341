import {
  UniversalLogger,
  ClientLoggerConfiguration,
} from '@leaseplan/universal-logger'

import * as buildTimeEnv from 'mlp-client/src/config/buildTimeEnv'

const clientConfig: ClientLoggerConfiguration = {
  isServer: false,
  isProductionDeployment: window.__DD_ENV__ === 'prod',
  ddClientToken: buildTimeEnv.DD_CLIENT_TOKEN,
  ddEnv: window.__DD_ENV__,
  ddService: window.__DD_SERVICE__,
  ddTraceId: window.__DD_TRACE_ID__,
  ddSpanId: window.__DD_SPAN_ID__,
}

// Create client-only instance for the universal logger.
const log = new UniversalLogger(clientConfig)

export default log
