import { NavigationItem } from 'mlp-client/src/types'
import { Navigation } from 'mlp-client/src/features-configuration/types'
import { navigation as defaultNavigation } from 'mlp-client/src/features-configuration/default/navigation'
import { isLeaseplan } from 'mlp-client/src/VelocitySelector'

export const NAVIGATION_STRUCTURE: NavigationItem[] = [
  ...defaultNavigation.ONLY_DASHBOARD_NAVIGATION_STRUCTURE,
  {
    title: 'myLeaseplan.subNavigation.carContract',
    to: 'myLeaseplan.contract.detail',
    useContractId: !isLeaseplan(),
  },
]

export const MULTIPLE_VEHICLES_NAVIGATION_STRUCTURE: NavigationItem[] = [
  ...defaultNavigation.MULTIPLE_ORDERS_NAVIGATION_STRUCTURE,
]

export const navigation: Navigation = {
  ...defaultNavigation,
  NAVIGATION_STRUCTURE,
  MULTIPLE_VEHICLES_NAVIGATION_STRUCTURE,
}
