/**
 * MLP-specific copy of src/app/utils/cookies.tsx
 *
 * Differences from the original:
 * - server cookies support removed
 */

export enum DebugFlags {
  SHOW_ALL_FEATURES = 'showAllFeatures',
  DEBUG_TRANSLATIONS = 'debugTranslations',
}

export interface CookieOptions {
  Path?: string
  Domain?: string
  Expires?: Date
  'Max-Age'?: number
  Secure?: true
  SameSite?: 'Strict' | 'Lax' | 'None'
}

export const getCookies = (): Record<string, string> =>
  document.cookie.length === 0
    ? {}
    : document.cookie.split('; ').reduce((acc, cookie) => {
        const [name, value] = cookie.split('=').map(decodeURIComponent)

        return { ...acc, [name]: value }
      }, {})

/**
 * @returns hostname without subdomains
 * test-www.leaseplan.com -> leaseplan.com
 * localhost:3000 -> localhost
 */
const getDomain = (): string => {
  const hostname = window.location.hostname.split('.')

  return hostname.slice(-2).join('.')
}

export const COOKIE_EXPIRATION_DURATION = 900

export const getCookie = (name: string): string => getCookies()[name]

export const setCookie = (
  name: string,
  value: string,
  options: CookieOptions = {},
): void => {
  const optionsWithDefaults: CookieOptions = {
    Path: '/',
    Domain: getDomain(),
    ...(window.__ENV__ === 'development' && window.location.protocol === 'http:'
      ? {}
      : { Secure: true }),
    SameSite: 'Lax',
    ...options,
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`

  Object.entries(optionsWithDefaults).forEach(([optionKey, optionValue]) => {
    if (!optionValue) {
      return
    }

    updatedCookie += `; ${optionKey}`

    // Check for attributes that don't require value (Secure, HttpOnly)
    if (optionValue !== true) {
      // Expires must be in UTC format
      updatedCookie += `=${
        optionValue instanceof Date ? optionValue.toUTCString() : optionValue
      }`
    }
  })

  document.cookie = updatedCookie
}

export const deleteCookie = (name: string) => {
  setCookie(name, '', {
    Path: '/',
    Domain: getDomain(),
    'Max-Age': -1,
  })
}
